
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class ReportApiService extends BaseService {
    constructor(public apiService: ApiService) { super(apiService, 'ReportApi'); }
    public reportProfit(data: any) { return this.apiService.post(`${this.apiName}/ReportProfit`, data); }
    public reportReceiptAndPayment(data: any) { return this.apiService.post(`${this.apiName}/ReportReceiptAndPayment`, data); }
    public reportGeneralRP(data: any) { return this.apiService.post(`${this.apiName}/ReportGeneralRP`, data); }
    public dashboardSchool() { return this.apiService.get(`${this.apiName}/DashboardSchool`); }
    public dashboardCenter() { return this.apiService.get(`${this.apiName}/DashboardCenter`); }
    public reportTutionByClass(data: any) { return this.apiService.post(`${this.apiName}/ReportTutionByClass`, data); }
    public reportTutionBySchool(data: any) { return this.apiService.post(`${this.apiName}/ReportTutionBySchool`, data); }
    public exportReportTutionByClass(data: any) { return this.apiService.post(`${this.apiName}/ExportReportTutionByClass`, data); }
    public exportReportTutionBySchool(data: any) { return this.apiService.post(`${this.apiName}/ExportReportTutionBySchool`, data); }
    public reportChildHealth(data: any) { return this.apiService.post(`${this.apiName}/ReportChildrenHealth`, data); }

}
