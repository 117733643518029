import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { LoDashStatic } from 'lodash';
import * as moment from 'moment';

@Injectable()
export class UtilityService {
    public lodash: LoDashStatic = _;
    public moment = moment;

    constructor() {

    }
    
    public getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

}