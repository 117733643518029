import { Directive, ElementRef, Renderer2 } from "@angular/core";
import { BehaviorSubject } from "rxjs";
@Directive({
  selector: "[maximize]",
  exportAs: "maximize" // <-- Make not of this here
})
export class MaximizeDirective {
  private isMaximizedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isMaximized$: any = this.isMaximizedSubject.pipe();

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  toggle() {
    this.isMaximizedSubject.getValue() ? this.minimize() : this.maximize();
  }
  maximize() {
    if (this.el) {
      this.isMaximizedSubject.next(true);
      this.renderer.addClass(this.el.nativeElement, "fullscreen");
      document.getElementsByTagName('mat-sidenav')[0].classList.add('d-none')
    }
  }
  minimize() {
    if (this.el) {
      this.isMaximizedSubject.next(false);
      this.renderer.removeClass(this.el.nativeElement, "fullscreen");
      document.getElementsByTagName('mat-sidenav')[0].classList.remove('d-none')
    }
  }
  get isFullScreen() {
    return this.isMaximizedSubject.getValue();
  }
}
